#projects{
    /* background-color: #333; */
    
    }
    #projects h2{
        color: #fff;
    }
    .card{
        /* box-shadow: 10px 10px ; */
        
    }
    .card-body{
        background-color: #f3f3f3;
        color: #000;
        
    }