/* Ensure the navbar takes up the full width */
.navbar {
  width: 100%;
}

/* Style the navbar links */
.nav-link {
  color: #333;
  gap: 20px;
  box-shadow: 10px 10px 50px #332626;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color:  #332626 !important; 
  transform: rotateX(45deg);
  width: 75px;/* Change this color to whatever you like for hover effect */
}

/* Style for the active link */
.nav-link.active {
  /* font-weight: bold; */
}
